// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-download-js": () => import("./../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-location-support-js": () => import("./../src/pages/location-support.js" /* webpackChunkName: "component---src-pages-location-support-js" */),
  "component---src-pages-order-js": () => import("./../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-partner-etix-js": () => import("./../src/pages/partner/etix.js" /* webpackChunkName: "component---src-pages-partner-etix-js" */),
  "component---src-pages-partner-gigwell-js": () => import("./../src/pages/partner/gigwell.js" /* webpackChunkName: "component---src-pages-partner-gigwell-js" */),
  "component---src-pages-partner-seetickets-js": () => import("./../src/pages/partner/seetickets.js" /* webpackChunkName: "component---src-pages-partner-seetickets-js" */),
  "component---src-pages-partner-toast-js": () => import("./../src/pages/partner/toast.js" /* webpackChunkName: "component---src-pages-partner-toast-js" */),
  "component---src-pages-pay-js": () => import("./../src/pages/pay.js" /* webpackChunkName: "component---src-pages-pay-js" */),
  "component---src-pages-policy-js": () => import("./../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-request-demo-js": () => import("./../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-request-sent-js": () => import("./../src/pages/request-sent.js" /* webpackChunkName: "component---src-pages-request-sent-js" */),
  "component---src-pages-tab-js": () => import("./../src/pages/tab.js" /* webpackChunkName: "component---src-pages-tab-js" */),
  "component---src-pages-text-to-pay-js": () => import("./../src/pages/text-to-pay.js" /* webpackChunkName: "component---src-pages-text-to-pay-js" */),
  "component---src-pages-thankyou-js": () => import("./../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

